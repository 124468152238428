










import { Component, Vue } from "vue-property-decorator";

@Component
export default class Dashboard extends Vue {
  private name = "Welcome to DPS Stock Management System"
  
  mounted () {
    this.$store.commit('FOOTER_VISIBLE', false);
  }
}
